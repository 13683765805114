import React, { useState } from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import classes from "./websites.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import odbirImage from "../../images/websites/ODBIR.png"
import tech4devImage from "../../images/websites/tech4dev.png"
import ondoWebsiteImage from "../../images/websites/ondo-website.png"
import developOndoImage from "../../images/websites/developondo.png"
import smeupturnImage from "../../images/websites/smeupturn.png"
import { HeaderVariants } from "../../components/header"
import CardsSlider from "../../components/CardsSlider/CardsSlider"
import { Link } from "gatsby"

const websites = [
  {
    link: "/case-studies/tech4dev",
    title: "TECH4DEV",
    description:
      "Prowoks is an electronic document management solution that helps to create, archive, track, approve and review documents.",
  },
  {
    link: "/case-studies/odirs",
    title: "ONDO STATE INTERNAL REVENUE SERVICE",
    description:
      "Prowoks is an electronic document management solution that helps to create, archive, track, approve and review documents.",
  },
  {
    link: "/case-studies/sme-upturn",
    title: "SMEUPTURN",
    description:
      "Prowoks is an electronic document management solution that helps to create, archive, track, approve and review documents.",
  },
  {
    link: "/case-studies/ondo-state-website",
    title: "ONDO STATE WEBSITE",
    description:
      "Prowoks is an electronic document management solution that helps to create, archive, track, approve and review documents.",
  },
  {
    link: "/case-studies/develop-ondo",
    title: "DEVELOP ONDO",
    description:
      "Prowoks is an electronic document management solution that helps to create, archive, track, approve and review documents.",
  },
]

const backgroundColors = ["#263841", "#E46D41", "#56B6C3", "#FF6F1F", "#80DED9"]
const iconBorderColors = ["#061015", "#A84824", "#357982", "#B9490C", "#65A29E"]
const websiteCircleIcon1Styles = [
  {
    top: "calc(100% - 200px)",
    left: "20px",
  },
  {
    left: "calc(100% - 220px)",
    top: "calc(100% - 50px)",
  },
  {
    left: "calc(100% - 90px)",
    top: "60%",
  },
  {
    left: "calc(100% - 90px)",
    top: "10%",
  },
  {
    left: "calc(50% - 60px)",
    top: "7%",
  },
]
const websiteCircleIcon2Styles = [
  {
    top: "20px",
    left: "calc(100% - 170px)",
  },
  {
    left: "100px",
    top: "-60px",
  },
  {
    left: "-50px",
    top: "100px",
  },
  {
    left: "-50px",
    top: "60%",
  },
  {
    left: "50px",
    top: "calc(100% - 40px)",
  },
]

const websiteImageLinks = [
  tech4devImage,
  odbirImage,
  smeupturnImage,
  ondoWebsiteImage,
  developOndoImage,
]

const Websites = () => {
  const [active, changeActive] = useState(0)

  return (
    <HomePageLayout pageTitle="Websites" headerVariant={HeaderVariants.dark}>
      <SEO title="Websites" />
      <main className={classes.websitesMain}>
        <ul className={classes.websitesList}>
          {websites.map(({ title, description, link }, index) => (
            <li className={classes.website}>
              <button
                className={[
                  classes.websiteHeader,
                  active === index ? classes.websiteHeaderActive : "",
                ].join(" ")}
                onClick={() => changeActive(index)}
              >
                {title}
              </button>
              <p
                className={[
                  classes.websiteDescription,
                  active === index ? classes.websiteDescriptionActive : "",
                ].join(" ")}
              >
                {description}
                <Link to={link} className={classes.websiteLink}>
                  CASE STUDY
                </Link>
              </p>
            </li>
          ))}
        </ul>
        <div
          style={{
            backgroundColor: backgroundColors[active],
          }}
          className={classes.websiteImages}
        >
          <span
            className={classes.websiteImagesCircleIcon}
            style={{
              borderColor: iconBorderColors[active],
              ...websiteCircleIcon1Styles[active],
            }}
          />
          <span
            className={classes.websiteImagesCircleIcon}
            style={{
              borderColor: iconBorderColors[active],
              ...websiteCircleIcon2Styles[active],
            }}
          />
          {websiteImageLinks.map((link, index) => (
            <img
              src={link}
              className={classes.websiteImage}
              style={{
                top: `${
                  index === active
                    ? "calc(50% - 175px)"
                    : (index - active) * 100 + "%"
                }`,
              }}
              alt=""
            />
          ))}
        </div>
        <CardsSlider
          websites={websites}
          backgroundColors={backgroundColors}
          websiteImageLinks={websiteImageLinks}
        />
        <div className={classes.solutionsLinks}>
          <Link to="/solutions/products" className={classes.solutionsLink}>
            PRODUCTS
          </Link>
          <Link to="/solutions/projects" className={classes.solutionsLink}>
            PROJECTS
          </Link>
        </div>
      </main>
    </HomePageLayout>
  )
}

export default Websites
