import React from "react"
import classes from "./CardsSlider.module.css"
import Slider from "react-slick/lib"
import { Link } from "gatsby"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: classes.cards,
  adaptiveHeight: true
}

const CardsSlider = ({ websites, backgroundColors, websiteImageLinks }) => (
  <Slider {...settings}>
    {websites.map(({ title, description, link }, i) => (
      <div className={classes.card} key={i}>
        <div
          className={classes.cardImageContainer}
          style={{
            backgroundColor: backgroundColors[i],
          }}
        >
          <img
            src={websiteImageLinks[i]}
            className={classes.cardImage}
            alt=""
          />
        </div>
        <h3 className={classes.cardHeader}>{title}</h3>
        <p className={classes.cardText}>{description}</p>
        <Link to={link} className={classes.cardLink}>
          CASE STUDY
        </Link>
      </div>
    ))}
  </Slider>
)

export default CardsSlider
